import React, { Component } from "react"
import Layout from "../../../layout"
import Gobblet from "../../../games/gobblet"

class GobbletPage extends Component {
  render() {
    return (
      <Layout>
        <Gobblet/>
      </Layout>
    )
  }
}

export default GobbletPage
